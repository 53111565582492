import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
} from '@stripe/react-stripe-js'
import './PaymentCard.css'
import { loadStripe } from '@stripe/stripe-js'
import { base_url } from '../../../utils/Api'

const FeeManagementDetails = () => {
  const stripePromise = loadStripe('pk_test_lrorep5QhKnveufJFvsgsvwq0008SwKv0V')
  const navigate = useNavigate()
  const isSidebarOpen = useSelector((state) => state.checkCloseOpen)
  const [student, setStudent] = useState({})
  const [detail, setDetail] = useState({})
  const [data, setData] = useState('')
  const [stripePay, setStripePay] = useState(false)
  const [studentPaidAmount, setStudentPaidAmount] = useState(0)
  const { _id } = useParams()
  const { feeManagementData } = useSelector((state) => state.feeManagmentDetail)
  console.log('ther ejreirjer', feeManagementData)
  console.log('the _id is', _id)

  // useEffect(() => {
  //   feeManagementData.map((item) => {
  //     if (item._id === _id) {
  //       setDetail(item)
  //     }
  //   })
  // }, [feeManagementData])
  async function getStudentData() {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url + `admin/applicationview/${_id}`, config)
      .then((res) => {
        setStudentPaidAmount(res.data.applications.totalamount)
      })
      .catch((err) => console.log('error is', err))
  }
  useEffect(() => {
    getStudentData()
    console.log('use', studentPaidAmount)
  })
  useEffect(() => {
    console.log('first', Array.isArray(feeManagementData))
    if (Array.isArray(feeManagementData)) {
      const item = feeManagementData.find((item) => item._id === _id)
      if (item) {
        setDetail(item)
        localStorage.setItem('detail', JSON.stringify(item))
      }
    } else {
      const dtail = localStorage.getItem('detail')
      console.log('first')
      console.log('detail', JSON.parse(dtail))
      setDetail(JSON.parse(dtail))
    }
  }, [feeManagementData, _id])
  var [netamount, setNetAmount] = useState(0)
  const [formData, setFormData] = useState({
    receiptNo: '',
    feeAmount: 0,
    dueDate: '',
    payDate: '',
    fine: 0,
    method: 'Cash', // Default payment method
    note: '',
    std_Id: _id,
  })
  const handleInputChange = (e) => {
    const { name, value } = e.target
    console.log('name', name, 'value', value)
    if (name == 'feeAmount') {
      setFormData({
        ...formData,
        [name]: Math.min(
          parseInt(detail.Fee) - studentPaidAmount,
          Math.max(0, value)
        ),
      })
    } else {
      setFormData({
        ...formData,
        [name]: value,
      })
    }
  }
  const calculateNetAmount = () => {
    // Implement your calculation logic here
    const feeAmount = parseFloat(formData.feeAmount) || 0
    const fine = parseFloat(formData.fine) || 0
    const netAmount = feeAmount + fine
    netamount = netAmount
    return netAmount.toFixed(2) // Format the net amount with two decimal places
  }
  const handlePayButtonClick = async () => {
    setStripePay(true)
  }

  console.log('the dfiodsfdsifjdkslfdskfj🤦‍♂️🤦‍♂️🤦‍♂️🤦‍♂️🤦‍♂️', detail)
  const getStudent = async () => {
    console.log('fjkldsflsfsl')
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url + `admin/applicationview/${_id}`, config)
      .then((res) => {
        console.log('the res is', res)
        setData(res.data.applications.feestatus)
        setStudent({
          Student_Name:
            res?.data?.applications?.Personal_Details?.First_Name_In_Passport,
          Session_Name: res?.data?.applications?.Course?.commencement,
          Course_Name: res?.data?.applications?.Course?.course_name,
        })
      })
      .catch((err) => console.log('error is---', err))
  }
  console.log('the student name is', student)
  useEffect(() => {
    getStudent()
  }, [_id])
  const Stripepay = (props) => (
    <Elements stripe={stripePromise && stripePromise}>
      <div>
        <MyComponent {...props} />
      </div>
    </Elements>
  )

  const MyComponent = (props) => {
    const stripe = useStripe()
    const elements = useElements()

    const handleSubmit = async (event) => {
      event.preventDefault()

      if (!stripe || !elements) {
        return
      }
      const config = {
        headers: {
          'Content-type': 'application/json',
        },
      }
      // const cardElement = elements.getElement(CardElement)

      // Use a valid test card number for testing
      console.log(
        'formdata',
        formData,
        'netamount',
        parseInt(formData.feeAmount) + parseInt(formData.fine)
      )
      axios
        .post(
          base_url + `admin/create-payment-intent`,
          {
            amount: parseInt(formData.feeAmount) + parseInt(formData.fine), // Example amount (in cents)
            currency: 'gbp',
          },
          config
        )
        .then(async (response) => {
          console.log('response.data.clientSecret', response.data.clientSecret)
          let clsec = response.data.clientSecret
          // const cardElement = elements.getElement(CardNumberElement)

          // Check if the user has entered their payment method

          const result = await stripe.confirmCardPayment(clsec, {
            payment_method: {
              card: elements.getElement(CardNumberElement),
            },
          })

          console.log(result)
          if (result.error) {
            console.error(result.error.message)
          } else {
            console.log(result)
            if (result.paymentIntent.status === 'succeeded') {
              const config = {
                headers: {
                  'Content-type': 'application/json',
                },
              }
              console.log()
              let feeAmt = parseInt(formData.feeAmount) + studentPaidAmount

              await axios
                .put(
                  base_url + `admin/applicationview/totalamount/${_id}`,
                  { feeAmt },
                  config
                )
                .then((res) => console.log('resp is', res))
                .catch((err) => console.log('error is'.err))
              if (parseInt(detail.Fee) == feeAmt) {
                let feestatus = 'Paid'
                await axios
                  .put(
                    base_url + `admin/applicationview/feestatus/${_id}`,
                    { feestatus },
                    config
                  )
                  .then((res) => console.log('resp is', res))
                  .catch((err) => console.log('error is'.err))
              }

              await axios
                .post(base_url + 'admin/course/addFee', formData, config)
                .then((response) => {
                  console.log('Response from server:', response.data)
                  // Handle success, e.g., show a success message to the user
                  toast.success('Payment successful')
                  navigate('/admin/fee-management')
                })
                .catch((error) => {
                  console.error('Error:', error)
                  toast.error('Payment failed')
                  // Handle errors, e.g., show an error message to the user
                })
              setStripePay(false)
            }
          }
        })
        .catch((error) => {
          console.error(error)
        })
      // Make the API call to send the formData to the backend
    }
    const options = {
      style: {
        base: {
          fontSize: '16px',
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
          fontSize: '16px',
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
          // Initial box-shadow
          boxShadow:
            'rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px',
          // Transition property
          transition: 'box-shadow 150ms ease',
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }
    return (
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '100px',
          marginBottom: '100px',
          width: '500px',
        }}
        onSubmit={handleSubmit}
      >
        <label>
          Card number
          <CardNumberElement
            className="form-control"
            onReady={() => {
              console.log('CardNumberElement [ready]')
            }}
            onChange={(event) => {
              console.log('CardNumberElement [change]', event)
            }}
            onBlur={() => {
              console.log('CardNumberElement [blur]')
            }}
            onFocus={() => {
              console.log('CardNumberElement [focus]')
            }}
          />
        </label>
        <label>
          Expiration date
          <CardExpiryElement
            className="form-control"
            // options={options}
            onReady={() => {
              console.log('CardNumberElement [ready]')
            }}
            onChange={(event) => {
              console.log('CardNumberElement [change]', event)
            }}
            onBlur={() => {
              console.log('CardNumberElement [blur]')
            }}
            onFocus={() => {
              console.log('CardNumberElement [focus]')
            }}
          />
        </label>
        <label>
          CVC
          <CardCvcElement
            className="form-control"
            onReady={() => {
              console.log('CardNumberElement [ready]')
            }}
            onChange={(event) => {
              console.log('CardNumberElement [change]', event)
            }}
            onBlur={() => {
              console.log('CardNumberElement [blur]')
            }}
            onFocus={() => {
              console.log('CardNumberElement [focus]')
            }}
          />
        </label>
        <button
          className="btn btn-primary"
          style={{ width: '60px' }}
          type="submit"
          disabled={!stripe}
        >
          Pay
        </button>
      </form>
    )
  }
  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <ToastContainer />

      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Fee-Management Details</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="http://clc-london.uk/dashboard">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Fee-Management Details
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              {stripePay ? (
                <div
                  className="card card-table"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Stripepay />
                </div>
              ) : (
                <div className="card card-table">
                  <div>
                    <div
                      className="card-header"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      <h5 className="card-title mb-2 font-weight-bold">
                        Personal Details
                      </h5>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm">
                          <div className="form-row">
                            <div className="col-md-6 mb-3 ">
                              <div className="input-group-prepend input-group mb-2">
                                <div
                                  className="input-group-text"
                                  style={{ borderRadius: '0px' }}
                                >
                                  Student ID
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue="Std-1243234"
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="col-md-6 mb-3 ">
                              <div className="input-group-prepend input-group mb-2">
                                <div
                                  className="input-group-text"
                                  style={{ borderRadius: '0px' }}
                                >
                                  Fee Type
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={detail.Installment || ''}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="col-md-6 mb-3 ">
                              <div className="input-group-prepend input-group mb-2">
                                <div
                                  className="input-group-text"
                                  style={{ borderRadius: '0px' }}
                                >
                                  Student Name
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={student.Student_Name}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="col-md-6 mb-3 ">
                              <div className="input-group-prepend input-group mb-2">
                                <div
                                  className="input-group-text"
                                  style={{ borderRadius: '0px' }}
                                >
                                  Session Name
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={student.Session_Name}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="col-md-6 mb-3 ">
                              <div className="input-group-prepend input-group mb-2">
                                <div
                                  className="input-group-text"
                                  style={{ borderRadius: '0px' }}
                                >
                                  Course Name
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={student.Course_Name}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="col-md-6 mb-3 ">
                              <div className="input-group-prepend input-group mb-2">
                                <div
                                  className="input-group-text"
                                  style={{ borderRadius: '0px' }}
                                >
                                  Course Fee £
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={detail.Fee || ''}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="col-md-6 mb-3 ">
                              <div className="input-group-prepend input-group mb-2">
                                <div
                                  className="input-group-text"
                                  style={{ borderRadius: '0px' }}
                                >
                                  Start Date
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={detail.Start_Date || ''}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="col-md-6 mb-3 ">
                              <div className="input-group-prepend input-group mb-2">
                                <div
                                  className="input-group-text"
                                  style={{ borderRadius: '0px' }}
                                >
                                  End Date
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={detail.End_Date || ''}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <form action>
                    <div
                      className="card-header"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      <h5 className="card-title mb-2 font-weight-bold">
                        Fee Details Form
                      </h5>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm">
                          <div className="form-row">
                            <div className="form-group col-md-6 mb-3">
                              <label className="col-lg- col-form-label">
                                Receipt No:{' '}
                              </label>
                              <div className="col-lg-">
                                <input
                                  type="number"
                                  name="receiptNo"
                                  className="form-control"
                                  value={formData.receiptNo}
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="invalid-feedback">
                                This field is required.
                              </div>
                              <div className="valid-feedback">Looks good.</div>
                            </div>
                            <div className="col-md-6 mb-3 ">
                              <label className=" col-form-label">
                                Fee Amount:{' '}
                              </label>
                              <div className="input-group-prepend input-group mb-2">
                                <div
                                  className="input-group-text"
                                  style={{ borderRadius: '0px' }}
                                >
                                  £
                                </div>
                                <input
                                  min="0"
                                  max={parseInt(detail.Fee) - studentPaidAmount}
                                  type="number"
                                  className="form-control"
                                  id="validationCustom02"
                                  placeholder="Enter fee amount"
                                  // Math.min(maxValue, Math.max(minValue, numericValue));
                                  value={Math.min(
                                    parseInt(detail.Fee) - studentPaidAmount,
                                    Math.max(0, formData.feeAmount)
                                  )}
                                  onChange={handleInputChange}
                                  name="feeAmount"
                                  required
                                />
                              </div>
                              <div className="invalid-feedback">
                                This field is required.
                              </div>
                              <div className="valid-feedback">Looks good.</div>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="col-md-6 mb-3">
                              <label htmlFor="validationCustom01">
                                Due Date:
                              </label>
                              <input
                                placeholder="dd/mm/yyyy"
                                type="date"
                                id="dueDate"
                                name="dueDate"
                                className="form-control date require"
                                value={formData.dueDate}
                                onChange={handleInputChange}
                                required
                              />
                              <div className="valid-feedback">Looks good!</div>
                              <div className="invalid-feedback">
                                This field is required.
                              </div>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label htmlFor="validationCustom02">
                                Pay Date:
                              </label>
                              <input
                                placeholder="dd/mm/yyyy"
                                type="date"
                                id="payDate"
                                name="payDate"
                                className="form-control date require"
                                value={formData.payDate}
                                onChange={handleInputChange}
                                required
                              />
                              <div className="valid-feedback">Looks good!</div>
                              <div className="invalid-feedback">
                                This field is required.
                              </div>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-6 mb-3">
                              <label className="col-lg- col-form-label">
                                Fine:{' '}
                              </label>
                              <div className="input-group-prepend input-group mb-2">
                                <div
                                  className="input-group-text"
                                  style={{ borderRadius: '0px' }}
                                >
                                  £
                                </div>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="fine"
                                  placeholder="Enter fine"
                                  value={formData.fine}
                                  onChange={handleInputChange}
                                  name="fine"
                                  required
                                />
                              </div>
                              <div className="invalid-feedback">
                                This field is required.
                              </div>
                              <div className="valid-feedback">Looks good.</div>
                            </div>
                            <div className="form-group col-md-6 mb-3">
                              <label className="col-lg- col-form-label">
                                Method:{' '}
                              </label>
                              <select
                                className="form-control form-control-lg"
                                value={formData.method}
                                onChange={handleInputChange}
                                name="method"
                                required
                                defaultValue="Cash"
                              >
                                <option>Cash</option>
                                <option>Bank</option>
                              </select>
                              <div className="invalid-feedback">
                                This field is required.
                              </div>
                              <div className="valid-feedback">Looks good.</div>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="col-md-6 mb-3 ">
                              <label className=" col-form-label">
                                Net Amount:{' '}
                              </label>
                              <div className="input-group-prepend input-group mb-2">
                                <div
                                  className="input-group-text"
                                  style={{ borderRadius: '0px' }}
                                >
                                  £
                                </div>
                                <input
                                  min="0"
                                  max={
                                    detail.Fee -
                                    studentPaidAmount +
                                    parseInt(formData.fine)
                                  }
                                  type="number"
                                  className="form-control"
                                  id="validationCustom02"
                                  placeholder="Calculated net amount"
                                  value={Math.min(
                                    parseInt(detail.Fee) -
                                      studentPaidAmount +
                                      parseInt(formData.fine),
                                    Math.max(0, calculateNetAmount())
                                  )} // You can implement the calculation logic
                                  name="netamount"
                                  readOnly
                                  required
                                />
                              </div>
                              <div className="invalid-feedback">
                                This field is required.
                              </div>
                              <div className="valid-feedback">Looks good.</div>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12 mb-3">
                              <label className="col-lg- col-form-label">
                                Note:{' '}
                              </label>
                              <div className="col-lg-">
                                <textarea
                                  type="text"
                                  rows={4}
                                  name="note"
                                  // name="pfname"
                                  className="form-control"
                                  value={formData.note}
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="invalid-feedback">
                                This field is required.
                              </div>
                              <div className="valid-feedback">Looks good.</div>
                            </div>
                          </div>
                          <div
                            className
                            style={{
                              display: 'flex',
                              width: '100%',
                              flexDirection: 'flex-end',
                            }}
                          >
                            <button
                              disabled={data == 'Paid'}
                              type="button"
                              style={{
                                backgroundColor: data == 'Paid' && 'grey',
                                borderColor: data == 'Paid' && 'grey',
                              }}
                              className="btn btn-primary"
                              onClick={handlePayButtonClick}
                            >
                              {data == 'Paid' ? 'Paid' : 'Pay'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
        <footer>
          <p>Copyright © 2020.</p>
        </footer>
      </div>
    </div>
  )
}

export default FeeManagementDetails
